import { Box, Button, Drawer, Fade, FormControlLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'
import React from 'react';
import * as ReactDOM from "react-dom";
import classes from "./SiteNavigationBarLoggedIn.module.scss";
import { getParentProfile } from '../../ProfileParent/Services/ProfileParent.api';
import { ProfileParentType } from '../../ProfileParent/ProfileParent.interface';

const fullName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
        return 'N/A'
    }

    if (!firstName || !lastName) {
        return <>{firstName || lastName}</>
    }

    return (
        <>
            {firstName} {lastName}
        </>
    )
}

declare global {
    interface Window {
        CurrentUserType: number;
        IsMiniSite: boolean;
        IsPublicSite: boolean;
        EnableVacancyAlertFeature: any;
        IsParentPlaybookEnabled: boolean;
        BaseUrl: string;
    }
}

const routeOfForChildCareService = [
    '/home/childcareproviders',
    '/home/childcareconnect',
    '/home/childcaredataservices',
    '/home/childcareagency',
    '/home/wallofpraise',
    '/articlesprovider/library',
    '/home/news',
    '/home/wewsrss'
];

const ButtonCustomOutlined = styled(Button)({
    border: ' 2px solid #5A60EC',
    '&.MuiButton-outlined': {
        color: '#5A60EC',
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '17px',
        borderRadius: '50px',
        fontFamily: 'Nunito',
        width: '100%'
    },
    '&:hover, &:focus': {
        boxShadow: 'none',
        border: ' 2px solid #5A60EC',
        backgroundColor: 'transparent',
    },
})


const SiteNavigationBarLoggedIn: React.FC = () => {
    const [pageStack, setPageStack] = React.useState([]);
    const [layoutModalMVC, setLayoutModelMVC] = React.useState<{
        CurrentUserType: number;
        IsMiniSite: boolean;
        IsPublicSite: boolean;
        EnableVacancyAlertFeature: any;
        IsParentPlaybookEnabled: boolean;
        BaseUrl: string;
    }>({
        CurrentUserType: 0,
        IsMiniSite: false,
        IsPublicSite: false,
        EnableVacancyAlertFeature: null,
        IsParentPlaybookEnabled: false,
        BaseUrl: "",
    })
    const [open, setOpen] = React.useState(false);
    const [profileParent, setProfileParent] = React.useState<ProfileParentType>({
        FirstName: "",
        LastName: "",
        Address: "",
        Suburb: "",
        Postcode: "",
        StateCode: "",
        MobilePhone: ""
    })
    const [checked, setChecked] = React.useState(false);
    const toggleDrawer = (newOpen) => {
        setOpen(newOpen);
    };

    React.useEffect(() => {
        const pathname = window.location.pathname;
        const isValidPathname = routeOfForChildCareService.includes(pathname);
        if (isValidPathname) {
            setChecked(true)
        }
        else {
            setChecked(false)
        }
    }, [])


    React.useEffect(() => {
        window.addEventListener('toggle-drawer-event', () => {
            setOpen(true);
        });

        // Don't forget to clean up
        return () => {
            window.removeEventListener('toggle-drawer-event', toggleDrawer);
        };
    }, []);


    React.useEffect(() => {
        if (open) {
            getParentProfile()
                .then((res: ProfileParentType) => {
                    setProfileParent(res)
                })
        }

    }, [open]);


    React.useEffect(() => {
        if (typeof window !== "undefined") {
            setLayoutModelMVC({
                CurrentUserType: window.CurrentUserType,
                IsMiniSite: window.IsMiniSite,
                IsPublicSite: window.IsPublicSite,
                EnableVacancyAlertFeature: window.EnableVacancyAlertFeature,
                IsParentPlaybookEnabled: window.IsParentPlaybookEnabled,
                BaseUrl: window.BaseUrl,
            })
        }
    }, [])

    const authButtonClick = () => {
        const currentURL = window.location.href;
        const containsChildCareProviders = currentURL.includes("/childcareproviders");

        if (containsChildCareProviders) {

            window.open(`${layoutModalMVC.BaseUrl}/signin`, '_blank');
        } else {

            if (pageStack.length <= 0) {

                setPageStack(prevStack => [...prevStack, "emailPage"]);
            }


            const authModal = document.getElementById('authModal');
            if (authModal) {
                authModal.classList.remove('hidden');
                setOpen(false)
            }
        }
    };

    return (
        <div className={classes.pageContentContainer}>
            <Drawer open={open} onClose={() => {
                toggleDrawer(false)
            }} anchor={'right'}>
                <Box
                    sx={{
                        width: '350px',
                        position: 'relative',
                        padding: '20px'
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                        spacing={1}
                        sx={{ background: 'white' }}
                    >
                        <IconButton
                            onClick={() => toggleDrawer(false)}
                            sx={{ padding: '5px' }}
                        >
                            <svg className="icon">
                                <use href="/img/icons/core-sprite.svg#close" xlinkHref="/img/icons/core-sprite.svg#close"></use>
                            </svg>
                        </IconButton>
                    </Stack>
                    {!layoutModalMVC.CurrentUserType ?
                        <ButtonCustomOutlined variant="outlined" size="large" onClick={authButtonClick}>
                            Sign in / Sign up
                        </ButtonCustomOutlined> :
                        <Stack direction="row" alignItems="center" spacing={1} gap={'16px'}>
                            <div className={classes.logo}>
                                <img src={"/account/userphoto"} />
                            </div>
                            <a href="/account/profile">
                                <div className={classes.fullInformation}>
                                    <div className={classes.fullName}>{fullName(profileParent?.FirstName, profileParent?.LastName)}</div>
                                    <Typography sx={{
                                        color: '#5a60ec',
                                        fontSize: 16,
                                        fontWeight: 500,
                                        textDecoration: 'underline'
                                    }}>Manage Account</Typography>
                                </div>
                            </a>
                        </Stack>}

                    <div className={classes.divider}></div>
                    <Typography sx={{
                        color: '#5a60ec',
                        fontSize: '15px',
                        fontWeight: 800,
                        marginBottom: '20px',
                        fontFamily: 'Nunito'
                    }}>FOR PARENTS</Typography>

                    <Stack gap="12px" justifyContent="center" sx={{ marginBottom: '20px' }}>
                        <div className={classes.subMenuItem}>TOOLS</div>
                        <a href="/child-care-subsidy-calculator" className={classes.menuItem}>Subsidy Calculator</a>
                        <a href="/home/childcarecosts" className={classes.menuItem}>Cost Calculator</a>
                        {layoutModalMVC.EnableVacancyAlertFeature && <a href="/home/vacancyalertenhancement" className={classes.menuItem}>Vacancy Alert</a>}
                        {layoutModalMVC.IsMiniSite && <a href="/home/specialoffers" className={classes.menuItem}>Offers</a>}
                    </Stack>
                    <Stack spacing={2} justifyContent="center">
                        <div className={classes.subMenuItem}>RESOURCES</div>
                        {layoutModalMVC.IsParentPlaybookEnabled && <a href="/parent-playbook" className={classes.menuItem}>Parent Playbook</a>}
                        <a href="/blog" className={classes.menuItem}>Blog</a>
                        <a href="/blog/mycollection">
                            <Stack direction="row" alignItems="center" justifyContent="space-between">

                                <div className={classes.menuItem}>My Collection</div>
                                <img src="/img/bookmark-outline.svg" alt="#" style={{ height: '15px' }} />

                            </Stack>
                        </a>
                        <a href="/home/childcarecompass" className={classes.menuItem}>Child Care Compass</a>
                        {layoutModalMVC.IsPublicSite && <a href="/childcare-guide" className={classes.menuItem}>Child Care Guide</a>}
                        <a href="/blog" className={classes.menuItem}>Articles & Tips</a>
                        <a href="https://www.childcarejobs.com.au/">
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <div className={classes.menuItem}>Child Care Jobs</div>
                                <img src="/img/logout.svg" alt="#" style={{ height: '15px' }} />
                            </Stack>
                        </a>

                        <a href='/school-readiness' className={classes.menuItem}>School Readiness</a>
                        <a href='/open-day' className={classes.menuItem}> Virtual Open Day</a>                         
                    </Stack>

                    {layoutModalMVC.IsPublicSite &&
                        <>
                            <div className={classes.divider}></div>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{
                                marginBottom: checked ? '20px' : '0px',
                                cursor: 'pointer'
                            }} onClick={() => setChecked((prev) => !prev)}>
                                <Typography sx={{
                                    color: '#5a60ec',
                                    fontSize: '15px',
                                    fontWeight: 800,

                                    fontFamily: 'Nunito'
                                }}>FOR CHILD CARE SERVICES</Typography>
                                <img src="/img/arrow-down.svg" style={{
                                    rotate: !checked ? '0deg' : '180deg'
                                }} />
                            </Stack>


                            <div className={checked ? '' : 'hidden'}>
                                <Stack gap="12px" justifyContent="center" sx={{ marginBottom: '20px' }}>
                                    <div className={classes.subMenuItem}>OUR SERVICES</div>
                                    <a href="/home/childcareproviders" className={classes.menuItem}>Service Overview</a>
                                    {layoutModalMVC.EnableVacancyAlertFeature && <a href="/home/childcareconnect" className={classes.menuItem}>Child Care Connect</a>}
                                    <a href="/home/childcaredataservices" className={classes.menuItem}>Data Services</a>
                                    <a href="/home/childcareagency" className={classes.menuItem}>Agencies</a>
                                </Stack>
                                <Stack spacing={2} justifyContent="center">
                                    <div className={classes.subMenuItem}>RESOURCES</div>
                                    <a href="/home/wallofpraise" className={classes.menuItem}>Parent Testimonials</a>
                                    <a href="/articlesprovider/library" className={classes.menuItem}>Articles &amp; resources</a>

                                    <a href='/home/news' className={classes.menuItem}>Newsletters</a>
                                    <a href="https://www.childcarejobs.com.au/">
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">

                                            <div className={classes.menuItem}>Child Care Jobs</div>
                                            <img src="/img/logout.svg" alt="#" style={{ height: '15px' }} />

                                        </Stack>
                                    </a>
                          
                                </Stack>
                            </div>




                        </>
                    }

                    {layoutModalMVC.CurrentUserType &&
                        <>
                            <div className={classes.divider}></div>
                            <a href="/account/logout">
                                <Typography sx={{ color: '#5E5A6F', fontSize: '18px', fontWeight: 700, fontFamily: 'Nunito' }}>Logout</Typography>
                            </a>
                        </>}

                </Box>
            </Drawer>
        </div>


    )
}

ReactDOM.render(<SiteNavigationBarLoggedIn />, document.getElementById("siteNavigationBarLoggedIn"));